const config = {
  mapConfig: {
    amapKey: "a676350d2054a7a0fafc148806b18f4c",
    amapStyle: "amap://styles/719a3793d2a39ce33f05491377a15537"
  },
  // Encrypt's key
  aesKey: "987654321012date",
  aesIv: "data560123456789",

  serverUrls: {
    // 获取用户位置信息
    getCurLocation: "/api/search/location",

    searchMatching: "/api/search/matching_words", //搜索联想词
    searchRecord: "/api/search/behavior/record", //搜索行为记录
    searchData: "/api/search", // 数据查询
    getCommonInfo: "/api/search/common", // 详情
    getCodeInfo: "/api/search/code_info",

    // 收藏相关
    addCollection: "/api/user/collection/add", //收藏
    deleteCollection: "/api/user/collection/delete", //取消收藏
    getCollStatus: "/api/user/check_project_status", //查看当前详情是否被收藏
    checkCollectStatus: "/api/user/check_projects_status", // 查询多个项目
    getCollectionList: "/api/user/collection/list", //获取用户收藏列表

    //认领相关
    getBrandList: "/api/brand/list", //品牌认领列表
    setBrandClaim: "/api/brand/claim", //去认领
    cancelBrandClaim: "/api/brand/cancel", //取消认领
    brandAudit: "/api/brand/audit", //认领审批
    getBrandClaimSmsCaptche: "/api/brand/sms", //发送认领短信验证码
    getBrandStatus: "/api/brand/status", //获取认领状态

    // 周边查询
    reachableDomain: "/api/search/reachable_domain", // 可达域查询

    //用户相关
    getUserInfo: "/api/user/user_info", //获取用户信息
    uploadImg: "/api/user/upload_avatar", //上传「头像/营业执照」
    updateUserInfo: "/api/user/update", //更新用户头像/昵称/邮箱
    getSmsCaptcha: "/api/user/send_sms_capt", //获取手机验证码
    checkSmsCaptche: "/api/user/check_sms_captche", //验证手机号验证码
    bindWechat: "", //微信绑定
    upDatePwd: "/api/user/reset_password", //密码更新
    getImgCaptcha: "/api/user/get_img_captche", //获取图形验证码
    verImgCaptcha: "/penelope/account/verify_img_captcha", //验证图形验证码
    checkUser: "/penelope/admin/user/check", //用户名，手机号校验
    getEmailCaptcha: "/api/user/send_eamil_captcha", //发送邮箱验证码
    checkEmailCaptcha: "/api/user/valid_eamil_captcha", //验证邮箱验证码
    bindLoginWx: "/api/user/wechat_bind", //登录，绑定微信

    //分享相关
    getShareImg: "/api/user/share_code", //获取分享小程序码
    getShareLink: "/api/user/share_link", //获取分享链接
    saveScene: "/api/user/save_scene", //二维码参数存
    getScene: "/api/user/get_scene", //二维码参数取

    //VIP
    vipAsk: "/api/user/vip_ask", //VIP报价咨询

    // 未登录状态下，获取访客的唯一标志
    getVisitorUid: "/api/search/uuid", // 生成访客唯一标志

    // 未登录状态下，用户浏览详情记录次数判断
    getDetailBrowsingRecord: "/api/search/detailBrowsingRecord",

    //微信SDKLogin
    wxSDKLoginUrl:
      "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js",

    //用户搜索记录日志
    getBehaviorList: "/api/search/behavior/list",
    downloadBehaviorList: "/api/search/behavior/download",
    // 获取用户当前城市
    getCityCode: "/api/search/ip/adcode",

    SubmitFreeTrial: "/api/apply/hotmap",

    oriderList: "/api/user/order/list",
    getOrderQrcode: "/api/user/preorder",
    getOrderStatus: "/api/user/order/detail",
    getBrandTree: "/api/search/brand/category",
    getCategorySearch: '/api/search/brand/category/word',
    setClaimvip: '/api/user/claimvip',
    upgrade:"/api/search/upgrade/clue"
  }
};
module.exports = config;
