import { getDataByParams } from "./base.js";
const config = require("@/config/config." + process.env.NODE_ENV + ".js");
// 搜索数据-基础方法
export const SearchData = getDataByParams("searchData", "post", {
  encypt: config.encypt,
});
export const SearchMatching = getDataByParams("searchMatching", "post", {
  encypt: false,
});
// 搜索记录 searchRecord
export const SearchRecord = getDataByParams("searchRecord", "post", {
  encypt: false,
});
// 获取详情
export const GetCommonInfo = getDataByParams("getCommonInfo", "post", {
  encypt: config.encypt,
});
// 通过code获取详情
export const GetCodeInfo = getDataByParams("getCodeInfo", "post");
// 获取用户位置信息
export const GetCurLocation = getDataByParams("getCurLocation", "get");
// 可达域查询
export const ReachableDomain = getDataByParams("reachableDomain", "post");

// 获取分享小程序码
export const GetShareImg = getDataByParams("getShareImg", "post");
// 获取分享链接
export const GetShareLink = getDataByParams("getShareLink", "post");
// 二维码参数存
export const SaveScene = getDataByParams("saveScene", "post");
// 二维码参数取
export const GetScene = getDataByParams("getScene", "post");

// 获取游客唯一身份标识
export const GetVisitorUid = getDataByParams("getVisitorUid", "get");

// 用户浏览详情记录次数判断
export const GetDetailBrowsingRecord = getDataByParams(
  "getDetailBrowsingRecord",
  "post",
  {
    encypt: config.encypt,
  }
);

//用户搜索记录日志
export const GetBehaviorList = getDataByParams("getBehaviorList", "post");
export const DownloadBehaviorList = getDataByParams(
  "downloadBehaviorList",
  "post"
);

// 获取用户所在城市
export const GetCityCode = getDataByParams("getCityCode", "get", {
  encypt: false,
});

// 获取品牌树
export const GetBrandTree = getDataByParams("getBrandTree", "get", {
  encypt: false,
});
// 品牌数模糊搜索
export const GetCategorySearch = getDataByParams("getCategorySearch", "get", {
  encypt: false,
});

// 免费试用
export const SubmitFreeTrial = getDataByParams("SubmitFreeTrial", "post", {
  encypt: false,
})