<template>
  <div class="app" id="app">
    <router-view />
    <Loading />
    <MonthlyCard v-if="checkLogin() && !showMonthlyCard"></MonthlyCard>
    <!-- <MonthlyCard v-if="checkLogin()"></MonthlyCard> -->
      <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
  </div>
</template>

<script>
import { login } from "js/utils.js";
import Loading from "./components/loading/Loading.vue";
import MonthlyCard from "./components/MonthlyCard.vue";
import ModalUpgrade from "./components/ModalUpgrade.vue"
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      showMonthlyCard: true,
      isShow:false
    };
  },
  metaInfo: {
    title: "",
    titleTemplate: (titleChunk) => {
      let str = "亿景知客-城市地图大数据平台";
      if (titleChunk) {
        str = titleChunk + " - " + str;
      }
      return str;
    }
    // meta: [
    //   {
    //     name: "keywords",
    //     // content:
    //     //   "千里目，大数据，品牌数据，位置数据，商圈数据，商场数据，城市数据，区域数据，客群画像，用户偏好，客群数据，客流趋势，销量趋势，业态分布，周边配套，创意投放，到访客流，商户分析，商业营销，全域数据，线上数据，线下数据，时空大数据，产业洞察，开店选址，竞品对比，深度分析，分析报告",
    //   },
    //   {
    //     name: "description",
    //     // content:
    //     //   "千里目是一个整合线下和线上数据的全域商业数据平台，提供查品牌、查位置、查商圈、查商场、查城市、查客流、查周边、查销量、查创意等智能查询功能，多维数据赋能商业发展，助力企业项目分析、价值提升、精准运营、降本增效。",
    //   },
    // ],
  },
  computed: {
    ...mapGetters(["getUserInfo"])
  },
  methods: {
    checkLogin() {
      return this.$store.state.isUserLogin;
    },
    gotoLogin() {
      if (!this.checkLogin()) {
        login();
        return false;
      }
      return true;
    },
    checkVIP() {
      let isVIP = this.$store.state.isVIP;
      return isVIP;
    },
    gotoVIP() {
      this.isShow = true
      // this.$Modal.info({
      //   title: "提示",
      //   content: "请先开通 SVIP，即可查询客流、销量、广告投放量等内容",
      //   closable: true,
      //   onOk: () => {
      //     window.open("/vip", "_blank");
      //   }
      // });
    },
    async checkCacheLogin() {

      let token = this.$store.getters.getToken();
      if (token) {
        this.$store.commit("clearVisitorUid");
        this.$store.commit("setToken", token);
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.showMonthlyCard = userInfo?.userMember?.hasClaimExperience;
        if (
          userInfo?.userMember?.key == "vip" ||
          userInfo?.userMember?.key == "svip"
        ) {
          document.querySelector("body").classList.add("qlm-vip");
        } else {
          document.querySelector("body").classList.remove("qlm-vip");
        }
      } else {
        // 未登录用户获取其游客标识
        
      }
    }
  },
  watch: {
    getUserInfo: {
      handler(val) {
        if (val) {
          this.showMonthlyCard = val?.userMember?.hasClaimExperience;
        }
      },
      deep: true
    }
  },
  mounted() {
    var _hmt = _hmt || [];
    setTimeout(() => {
      var _hmt = _hmt || [];
      (function() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?a69c3ddb4ba757b4981a558b17793770";
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
      })();
    }, 5000);
    this.checkCacheLogin();
    this.$global.checkVIP = this.checkVIP;
    this.$global.gotoVIP = this.gotoVIP;
    this.$global.checkLogin = this.checkLogin;
    this.$global.gotoLogin = this.gotoLogin;
  },
  components: {
    Loading,
    MonthlyCard,
    ModalUpgrade
  }
};
</script>

<style lang="less">
@import "@/assets/stylesheets/iview.less";
.app {
  width: 100%;
  height: 100%;
}
.qlm-vip {
  .g-vip-section,
  .g-vip-section-small {
    display: none;
  }
}
.g-login-padding {
  padding-bottom: 124px;
}
.ivu-modal-wrap {
  z-index: 99999 !important;
}
.g-visitor-dialog-section {
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  .g-visitor-info {
    font-size: 14px;
    color: #333;
    span {
      font-weight: 600;
      color: #3519fb;
    }
  }
  .g-visitor-tips {
    margin-top: 14px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.4);
    span {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}
.visitor-login {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 420px !important;
  height: 124px !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  backdrop-filter: blur(4px);
  .visitor-login-btn {
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: 182px;
    height: 40px;
    transform: translateX(-50%);
    line-height: 40px;
    background: #3519fb;
    border-radius: 4px;
    border: 1px solid #3519fb;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  .visitor-login-btn:hover {
    background: #5139ff;
  }
  .visitor-login-icon {
    margin-left: 8px;
    vertical-align: middle;
  }
}
.g-brand-detail-login {
  position: static !important;
  width: 100% !important;
  height: 66px !important;
  .visitor-login-btn {
    position: relative !important;
    top: 14px;
  }
  .visitor-login-btn:hover {
    cursor: pointer;
  }
}
.g-business-detail-login {
  height: 46px !important;
}
.visitor-login:hover {
  cursor: initial;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 100%
  ) !important;
  backdrop-filter: blur(4px) !important;
}

.g-vip-dialog-section {
  margin-top: 10px;
  height: 100px;
  // background: url("assets/images/vip/g-vip-dialog-bg.png") no-repeat center top;
  background: url("assets/images/vip/g-vip-dialog-bg2.png") no-repeat center top;
  background-size: 800px 61px;
  text-align: center;
  p {
    position: relative;
    top: 56px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    span {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}
.vip-dialog-wrapper {
  .ivu-modal-confirm-footer {
    margin-top: 0;
    text-align: center;
  }
  .ivu-modal-body {
    position: relative;
  }
  .ivu-modal-confirm {
    padding: 0;
  }
  .ivu-modal-confirm-body {
    position: inherit;
    padding-left: 0;
  }
  .ivu-icon-md-close:hover {
    cursor: pointer;
  }
  .ivu-btn-text {
    position: absolute;
    right: 22px;
    top: 22px;
    padding: 0;
  }
  .ivu-btn-text:focus {
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0 transparent;
  }
  .ivu-btn-text span {
    color: #999;
    font-size: 0;
    display: inline-block;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: optimizeLegibility;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: -0.125em;
    text-align: center;
  }
  .ivu-btn-text span::before {
    content: "\f379";
    color: #999;
    font-size: 24px;
  }
  .ivu-btn-primary {
    display: inline-block;
    width: 200px;
    height: 44px;
    line-height: 44px;
    // color: #6a3611;

    color: #faeed8;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    // background: linear-gradient(315deg, #ebba88 0%, #f8dbaf 100%);
    background: linear-gradient(134deg, #8c92b7 0%, #43496e 100%);
    border: 0;
    border-radius: 4px;
  }
  .ivu-btn-primary:hover {
    cursor: pointer;
    // color: #6a3611;

    color: #faeed8;
    // background: linear-gradient(315deg, #d99651 0%, #ffd89d 100%);

    background: linear-gradient(134deg, #707595 0%, #303553 100%);
  }
}
.visitor-dialog-wrapper {
  .ivu-btn-text {
    right: 15px;
    top: 15px;
  }
  .ivu-btn-primary {
    display: inline-block;
    margin-bottom: 20px;
    width: 100px;
    height: 32px;
    font-size: 14px;
    line-height: 32px;
    color: #ffffff;
    background: #3519fb;
    border-radius: 3px;
    border: 0;
  }
  .ivu-btn-primary:hover {
    cursor: pointer;
    color: #ffffff;
    background: #3519fb;
  }
}
.g-vip-section {
  width: 100%;
  // background: url("assets/images/vip/g-vip-bg.png") no-repeat center top;
  background: url("assets/images/vip/g-vip-bg2.png") no-repeat center top;
  // background-size: 604px 162px;
  background-size: 604px 70px;
  text-align: center;
  .diliver {
    width: calc(100% + 2px);
    height: 15px;
    background-color: rgba(0, 0, 0, 0.03);
  }
  .g-vip-btn {
    display: inline-block;
    margin-top: 78px;
    width: 200px;
    height: 44px;
    line-height: 44px;
    // color: #6a3611;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    // background: linear-gradient(315deg, #ebba88 0%, #f8dbaf 100%);
    background: linear-gradient(134deg, #8c92b7 0%, #43496e 100%);
    box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);
    border-radius: 4px;
  }
  .g-vip-btn:hover {
    cursor: pointer;
    // background: linear-gradient(315deg, #d99651 0%, #ffd89d 100%);
    background: linear-gradient(134deg, #707595 0%, #303553 100%);
    box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);
  }
  img {
    margin-top: 12px;
  }
}
.g-vip-section-small {
  margin-top: -12px;
  margin-bottom: 15px;
  // background: url("assets/images/vip/g-vip-bg-small.png") no-repeat center top;
  // background: url("assets/images/vip/g-vip-bg2.png") no-repeat center top;
  background: url("assets/images/vip/g-vip-bg3.png") no-repeat center top;

  background-size: 396px 66px;
  .diliver {
    width: calc(100% + 28px);
    height: 10px;
    margin-left: -20px;
    background-color: rgba(0, 0, 0, 0.03);
  }
  .g-vip-btn {
    width: 200px;
    height: 44px;
    margin-top: 60px;
    color: #faeed8;
    // background: linear-gradient(315deg, #ebba88 0%, #f8dbaf 100%);
    background: linear-gradient(134deg, #8c92b7 0%, #43496e 100%);
    box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);
    border-radius: 4px;
  }
}
.g-vip-icon {
  position: relative;
  top: -2px;
  margin-left: 3px;
}
</style>
