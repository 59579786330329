import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Header from "@/components/Header.vue";
import NoData from "@/components/NoData.vue";
import MapListTemplate from "@/components/MapListTemplate.vue";
import "@/assets/javascript/global";
import "style/base_style.less";
import "style/flex.less";
import "style/global.less";
import "js/iview.js";
import * as vClickOutside from "v-click-outside-x";
import { login, checkMobile } from "js/utils.js";

import "@/assets/javascript/directive.js";

import "swiper/css/swiper.css";
import "animate.css";
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import Meta from "vue-meta";
Vue.use(Meta);

import "./components/loading/loadingDirectory";

Vue.use(vClickOutside);
Vue.component("Header", Header);
Vue.component("NoData", NoData);
Vue.component("MapListTemplate", MapListTemplate);
Vue.config.productionTip = false;
window["changjing.ai"] = {
  VERSION: VERSION,
};

const env = process.env.NODE_ENV;
const config = require(`./config/config.${env}.js`);
Vue.prototype.$config = Vue.envConfig = config;

const doLogout = () => {
  store.commit("clearToken");
  login();
};
Vue.prototype.$global.doLogout = doLogout;

import {
  editEmail,
  bindEmail,
  editPhone,
  bindPhone,
  editPwd,
  bindWx,
} from "@/components/user/edit/index.js";
Vue.prototype.$global.editEmail = editEmail;
Vue.prototype.$global.bindEmail = bindEmail;
Vue.prototype.$global.bindPhone = bindPhone;
Vue.prototype.$global.editPhone = editPhone;
Vue.prototype.$global.editPwd = editPwd;
Vue.prototype.$global.bindWx = bindWx;

let isMobile = checkMobile();
Vue.prototype.$global.isMobile = isMobile;

(function(window) {
  setRem();

  function setRem() {
    if (!isMobile) {
      return;
    }
    var html = document.documentElement;
    var width = html.clientWidth;
    if (width > 790) {
      width = 790;
    }
    var fontSize = width / 37.5 + "px";
    Vue.prototype.$global.fontSize = parseFloat(fontSize.replace("px", ""));
    html.style.fontSize = fontSize;

    var dpr = 0;
    var scale = 0;
    if (!dpr && !scale) {
      var devicePixelRatio = window.devicePixelRatio;
      if (window.navigator.appVersion.match(/iphone/gi)) {
        if (devicePixelRatio >= 3 && (!dpr || dpr >= 3)) {
          dpr = 3;
        } else if (devicePixelRatio >= 2 && (!dpr || dpr >= 2)) {
          dpr = 2;
        } else {
          dpr = 1;
        }
      } else {
        dpr = 1;
      }
      scale = 1 / dpr;
    }
    html.setAttribute("data-dpr", dpr);
    Vue.prototype.$global.dpr = dpr;
  }

  var _resize = "orientation" in window ? "deviceorientation" : "resize";
  window.addEventListener(_resize, setRem, false);
})(window);

export default new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  mounted() {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount("#app");
